



















































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { FarmViewModel } from '../viewmodels/farm-viewmodel'
@Observer
@Component({})
export default class FarmLPDialog extends Vue {
  @Inject() vm!: FarmViewModel

  getLP() {
    window.open(`https://pancakeswap.finance/v2/add/BNB/${this.vm.farmHandler!.rewardToken}`, '_blank')
  }
}
